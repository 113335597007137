import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { StateFrom } from 'xstate'
import { useMachine as useStateMachine } from '@xstate/react'
import { stateMachine } from './stateMachine'
import { Event, UpdateTemplateEvent } from '../types'
import {
  StudioFont,
  StudioGroup,
  StudioTemplate,
  StudioUser,
} from '../../types'
import { useService } from '../../services'
import {
  useAddNotification,
  useDismissAllNotifications,
} from '../../components/Notifications'
import { createValidationErrorMessage } from '../../utils/createValidationErrorMessage'
import { validateElements } from '../../utils'
import { decodePath, pathToURI } from '../../utils/path'
import { StudioIntent } from '../../__graphql__/types'
import { useNavigate } from 'react-router-dom'
import { useTemplateProvider } from '../../contexts/TemplateProvider'

type MachineContext = {
  state: StateFrom<typeof stateMachine>
  send: (e: Event) => StateFrom<typeof stateMachine>
}

const machineContext = createContext({
  state: {} as StateFrom<typeof stateMachine>,
  send: (_: Event) => {},
})

type StateMachineProviderProps = {
  user: StudioUser
  onFetchTemplate: (template: StudioTemplate) => Promise<void>
  onFetchRootGroup: (group: StudioGroup) => void
  onFetchGroupByPath: (group: StudioGroup) => void
  onFetchFonts: (fonts: StudioFont[]) => void
  onFetchIntents: (intents: StudioIntent[]) => void
}

export const StateMachineProvider: FC<
  PropsWithChildren<StateMachineProviderProps>
> = ({
  children,
  onFetchTemplate,
  onFetchRootGroup,
  onFetchGroupByPath,
  onFetchFonts,
  onFetchIntents,
  user,
}) => {
  const {
    updateTemplate,
    uploadImage,
    fetchTemplate,
    fetchRootGroups,
    fetchGroupByPath,
    fetchFonts,
    fetchIntents,
    fetchPath,
    waitForTemplateReadyStatus,
  } = useService()
  const navigate = useNavigate()
  const addNotification = useAddNotification()
  const dismissAllNotifications = useDismissAllNotifications()
  const templateProvider = useTemplateProvider()

  const setTemplate = useCallback(
    (template: StudioTemplate) => {
      templateProvider.dispatch({ type: 'SET_TEMPLATE', template })
    },
    [templateProvider],
  )

  const [state, send] = useStateMachine(stateMachine, {
    devTools: true,
    context: {
      addNotification,
      dismissAllNotifications,
    },
    services: {
      fetchTemplate: async context => {
        const template = await fetchTemplate(context.selectedTemplateId!)
        await onFetchTemplate(template as StudioTemplate)
      },
      fetchPath: async context => {
        if (context.selectedPath) {
          const pathToFetch = decodePath(context.selectedPath!)
          const node = await fetchPath(pathToFetch)
          if (node.__typename === 'StudioGroup') {
            context.selectedTemplateId = null
            onFetchGroupByPath(await fetchGroupByPath(pathToFetch))
          } else if (node.__typename === 'StudioGroupTemplate') {
            context.selectedTemplateId = node.id
          }
        }
      },
      fetchGroupByPath: async context => {
        if (!context.hasFetchError && context.selectedPath) {
          const pathToFetch = decodePath(context.selectedPath)
          onFetchGroupByPath(await fetchGroupByPath(pathToFetch))
        }
      },
      updateTemplate: async (context, event) => {
        dismissAllNotifications()

        const { template } = event as UpdateTemplateEvent
        const {
          groupMapping: { parentPath: path },
        } = template

        const result = await updateTemplate(template, user, [
          'StudioTemplateValidationErrorResult',
        ])
        const fonts = await fetchFonts()

        if (result.__typename === 'StudioUpdatedTemplate') {
          context.signedImageUrls = result.signedImageUrls
          context.signedMaskUrls = result.signedMaskUrls
          context.template = template

          // Re-fetch the root groups so that
          // the new template shows up in Template Explorer
          await onFetchGroupByPath(await fetchGroupByPath(path))
        }

        if (result.__typename === 'StudioTemplateValidationErrorResult') {
          const validationErrorMessages = createValidationErrorMessage(
            result.errors,
            template,
            fonts,
          )
          const elementsWithValidationErrors = result.errors.map(
            err => err.elementIndex ?? 0,
          )

          setTemplate({
            ...template,
            elements: (
              await validateElements(
                template.elements,
                elementsWithValidationErrors,
              )
            ).validatedElements,
          })

          validationErrorMessages.forEach((err, index) => {
            addNotification({
              id: `Saving Validation Error - ${index}`,
              variant: 'error',
              text: err,
            })
          })

          throw new Error(result.__typename)
        }
      },
      uploadImages: async ({ signedImageUrls, signedMaskUrls, template }) => {
        const sourceTemplate = template as StudioTemplate

        await Promise.all([
          ...signedImageUrls.map(async signedImageUrl => {
            const imageElement =
              sourceTemplate.elements[signedImageUrl.elementIndex]

            if (imageElement.__typename === 'StudioImageElement') {
              return uploadImage(imageElement.image.url, signedImageUrl.url)
            }
          }),
          ...signedMaskUrls.map(async signedMaskUrl => {
            const imageElement =
              sourceTemplate.elements[signedMaskUrl.elementIndex]
            if (
              imageElement.__typename === 'StudioImageElement' &&
              imageElement.mask
            ) {
              return uploadImage(imageElement.mask!.url, signedMaskUrl.url)
            }
          }),
        ])
      },
      fetchTemplateData: async () => {
        onFetchRootGroup(await fetchRootGroups())
      },
      templateStatus: async ({ selectedTemplateId }) => {
        await waitForTemplateReadyStatus(selectedTemplateId!)
      },
      fetchFonts: async () => {
        onFetchFonts(await fetchFonts())
      },
      fetchIntents: async () => {
        onFetchIntents(await fetchIntents())
      },
      redirectToTemplate: async context => {
        navigate({
          pathname: `/${context.selectedTemplateId}`,
          search: window.location.search,
        })
      },
      setHasError: async context => {
        context.hasFetchError = true
      },
      clearFetchError: async context => {
        const pathTo = pathToURI(context.selectedPath || '')
        navigate({
          pathname: pathTo
            ? `/${pathTo.split('>').slice(0, -1).join('/')}`
            : '/recently-opened',
          search: window.location.search,
        })
        context.hasFetchError = false
        context.selectedPath = ''
      },
    },
  })

  const value: MachineContext = useMemo(
    () => ({
      state,
      send,
    }),
    [state, send],
  )

  return (
    <machineContext.Provider value={value}>{children}</machineContext.Provider>
  )
}

export const useMachine = () => useContext(machineContext)
