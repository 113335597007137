import {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  PropsWithChildren,
} from 'react'
import {
  GlobalStyle,
  LoadingIndicator,
  ScreenReaderOnly,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import {
  SceneSurface,
  SceneSurfaceCustom,
  SceneSurfaceData,
  SceneSurfaceDataState,
  useSceneSurfaceData,
} from '@moonpig/renderer-react'
import { Scene } from '@moonpig/renderer-scene-types'
import { StateMachineProvider, useMachine } from '../state/xstate'
import {
  isImageElement,
  isRectangleElement,
  isTextElement,
  validateElements,
} from '../utils'
import { useElementData, useTemplateValue } from '../data'
import { InteractiveImage } from './InteractiveImage'
import { ElementSelectionControls } from './ElementSelectionControls'
import {
  Notifications,
  NotificationsProvider,
  useDismissAllErrorNotifications,
  useDismissAllNotifications,
} from './Notifications'
import {
  TemplateExplorer,
  TemplateExplorerProvider,
  useTemplateExplorer,
} from './TemplateExplorer'
import { TemplateGlobalToolbar } from './TemplateToolbar/TemplateGlobalToolbar'
import { SaveAsDialog } from './Dialogs'
import { DebugToolbar } from './Debug/DebugToolbar'
import { STUDIO_BG_GREY } from '../constants'
import { StudioFont, StudioGroup, StudioUser } from '../types'
import { useService } from '../services'
import { useFeatureToggle } from '../contexts/FeatureToggleProvider'
import { InteractiveRectangle } from './InteractiveRectangle'
import { SceneContainer } from './SceneContainer'
import { TransformToolSurface } from './TransformToolSurface'
import { SceneElementEditControls } from './SceneElementEditControls'
import { TemplateSettingsControls } from './SceneElementEditControls/TemplateSettingsControls'
import { InteractiveEditableText } from './InteractiveTextFrame'
import { encodePath } from '../utils/path'
import { UploadMachineProvider } from './TemplateExplorer/UploadMachineProvider'
import { setRecentlyOpened } from './TemplateExplorer/lib'
import { useIsMounted } from '../hooks/useIsMounted'
import { ReviewOverlay } from './ReviewOverlay'
import { ActionsToolbar } from './Scene/ActionsToolbar'
import { SceneProvider, useSceneProvider } from '../contexts/SceneProvider'
import { TransformScene } from '../services/TransformScene'
import { AlternativeFontsPopup } from './AlternativeFontsPopup'
import { FontUploadMachineProvider } from './TemplateExplorer/UploadFontMachineProvider'
import { useBlocker, useLocation, useNavigate } from 'react-router-dom'
import { FontProvider, useFontProvider } from '../contexts/FontProvider'
import { IntentProvider, useIntentProvider } from '../contexts/IntentProvider'
import { StudioIntent, StudioTemplate } from '../__graphql__/types'
import {
  TemplateProvider,
  useTemplateProvider,
} from '../contexts/TemplateProvider'
import { LeaveDialog } from './Dialogs/Leave'
import { colorValue } from '@moonpig/launchpad-theme'
import { FILE_TOO_BIG_FAILED_ACCEPT_MESSAGE } from '../strings'
import { useErrorMessages } from '../hooks'

const HEADER_HEIGHT_PX = 64
const TOOLBAR_HEIGHT_PX = 64
const DEBUG_TOOLBAR_HEIGHT_PX = 100

const StyledMain = styled.main`
  background-color: ${STUDIO_BG_GREY};
`

const StyledMainContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  isolation: isolate;
`

const StyledStudioEditLoaded = styled.div<{
  size: { width: number; height: number }
}>`
  width: ${props => props.size.width || 0}px;
  height: ${props => props.size.height || 0}px;
  overflow: hidden;
`

const StyledSideMenu = styled.div`
  background-color: ${colorValue('colorBackground01')};
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  height: 100%;
  min-height: 0; /* CSS Grid sets items min-height to auto, set to 0 to allow overflow scroll. */
  overflow: hidden;
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 350px auto 350px;
  height: 100%;
`

const InteractiveElement: FC<{
  id: string
  x: number
  y: number
  width: number
  height: number
  unitsPerPixel: number
}> = ({ id, x, y, width, height, unitsPerPixel }) => {
  const { elementData } = useElementData(id)
  return (
    <>
      {isTextElement(elementData) && (
        <InteractiveEditableText
          id={id}
          x={x}
          y={y}
          width={width}
          height={height}
          rotation={elementData.rotation}
          elementData={elementData}
          unitsPerPixel={unitsPerPixel}
        />
      )}
      {isImageElement(elementData) && (
        <InteractiveImage
          id={id}
          x={x}
          y={y}
          width={width}
          height={height}
          rotation={elementData.rotation}
          unitsPerPixel={unitsPerPixel}
        />
      )}
      {isRectangleElement(elementData) && (
        <InteractiveRectangle
          id={id}
          x={x}
          y={y}
          width={width}
          height={height}
          rotation={elementData.rotation}
          unitsPerPixel={unitsPerPixel}
        />
      )}
    </>
  )
}

const useLastLoadedSurfaceData = (scene: Scene): SceneSurfaceDataState => {
  const result = useSceneSurfaceData(scene)
  const [current, setCurrent] = useState(result)

  useEffect(() => {
    if (result.type === 'LOADED') {
      setCurrent(result)
    }
  }, [result])

  return current
}

type SceneRendererProps = {
  scene: Scene
  surface: {
    type: 'LOADED'
    data: SceneSurfaceData
  }
  size: { width: number; height: number }
}

const SceneRenderer: FC<SceneRendererProps> = ({ scene, surface, size }) => {
  const sceneProvider = useSceneProvider()
  const { getTemplate } = useTemplateValue()
  const template = getTemplate()
  const { state } = useMachine()

  const isPreviewing = state.matches({ STUDIO: 'PREVIEWING' })
  const isSaving =
    state.matches({ STUDIO: 'UPDATE_TEMPLATE' }) ||
    state.matches({ STUDIO: { SAVE_AS: 'CREATE_NEW_TEMPLATE' } })

  const handleTransformToolDoubleClick = () => {
    if (sceneProvider.state.showInlineEditing) {
      sceneProvider.dispatch({ type: 'SET_HIDE_INLINE_EDITING' })
    } else {
      sceneProvider.dispatch({ type: 'SET_SHOW_INLINE_EDITING' })
    }
  }

  const { xBleed, yBleed } = template

  const shouldShowBleedRuler =
    Number.isFinite(xBleed) && Number.isFinite(yBleed)

  const scale =
    (size.width / surface.data.scene.width) * sceneProvider.state.zoom

  const editingText = useMemo(
    () => sceneProvider.state.showInlineEditing,
    [sceneProvider.state.showInlineEditing],
  )

  const missingFonts: string[] = useMemo(() => {
    return template.elements
      .map(element => {
        if (
          isTextElement(element) &&
          element.font.__typename === 'StudioFallbackFont'
        ) {
          return element.font.originalFont
        }
        return undefined
      })
      .filter(font => font !== undefined) as string[]
  }, [template])

  return (
    <StyledStudioEditLoaded size={size}>
      <SceneSurfaceCustom
        data={surface.data}
        size={size}
        renderObjectOverlay={({ object, unitsPerPixel }) =>
          isPreviewing || isSaving ? null : (
            <>
              {sceneProvider.state.customisationsVisible && (
                <ReviewOverlay id={object.id} {...object.frame} />
              )}
              <InteractiveElement
                id={object.id}
                unitsPerPixel={unitsPerPixel}
                {...object.frame}
              />
            </>
          )
        }
      >
        <SceneSurface
          data={surface.data}
          size={size}
          hideObject={({ object }) =>
            object.frame.width < 1 || object.frame.height < 1
          }
        />
        {sceneProvider.state.selectedElement?.id && !editingText && (
          <TransformToolSurface
            onDoubleClick={handleTransformToolDoubleClick}
            size={size}
            selectedElementId={sceneProvider.state.selectedElement.id}
            unitsPerPixel={1 / scale}
            style={{
              left: `${(xBleed as number) * scale}px`,
              top: `${(yBleed as number) * scale}px`,
            }}
          />
        )}
        {shouldShowBleedRuler && !isPreviewing && !isSaving && (
          <div
            data-testid="std-studio-bleed-ruler"
            style={{
              position: 'absolute',
              left: `${(xBleed as number) * scale}px`,
              top: `${(yBleed as number) * scale}px`,
              width: `${(scene.width - (xBleed as number) * 2) * scale}px`,
              height: `${(scene.height - (yBleed as number) * 2) * scale}px`,
              border: '1px dotted black',
              pointerEvents: 'none',
            }}
          />
        )}
      </SceneSurfaceCustom>

      {missingFonts?.length > 0 && !state.matches('TEMPLATE_EXPLORER') && (
        <AlternativeFontsPopup missingFonts={missingFonts} />
      )}
    </StyledStudioEditLoaded>
  )
}

type SceneRendererContainerProps = {
  onSceneChanges: (hasChanges: boolean) => void
}

const SceneRendererContainer: FC<SceneRendererContainerProps> = ({
  onSceneChanges,
}) => {
  const sceneContext = useSceneProvider()
  const templateProvider = useTemplateProvider()

  const { getTemplate, getTemplateScene } = useTemplateValue()

  const template = getTemplate()

  const scene = useMemo(
    () => TransformScene(getTemplateScene(), sceneContext.state.zoom),
    [getTemplateScene, sceneContext.state.zoom],
  )

  useEffect(() => {
    if (templateProvider.state.canUndo) {
      onSceneChanges(true)
    } else {
      onSceneChanges(false)
    }
  }, [
    templateProvider.state.canUndo,
    templateProvider.state.canRedo,
    onSceneChanges,
  ])

  const surface = useLastLoadedSurfaceData(scene)

  const divRef = useRef<HTMLDivElement | null>(null)

  if (surface.type === 'LOADING') {
    return <LoadingIndicator label="Loading Studio editor" />
  }

  if (surface.type === 'ERROR') {
    return (
      <>
        <h2>There was an error loading the template.</h2>
      </>
    )
  }

  return (
    <>
      <ScreenReaderOnly>
        <h2>Loaded {template.displayName}</h2>
      </ScreenReaderOnly>
      <div ref={divRef}>
        <SceneContainer
          sceneSize={scene}
          renderScene={size => (
            <SceneRenderer scene={scene} surface={surface} size={size} />
          )}
        />
      </div>
    </>
  )
}

const StudioMain: FC<{
  templateId: string
  logicalPath: string
  debugToolBarEnabled: boolean
}> = ({ templateId, logicalPath, debugToolBarEnabled }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const dismissAllNotifications = useDismissAllNotifications()
  const { searchTemplateByName } = useService()
  const { state, send } = useMachine()

  const { getTemplate } = useTemplateValue()
  const debugEnabled = useFeatureToggle('debug')

  const currentTemplateId = useRef<string>()
  const [templateSelection, setTemplateSelection] = useState<boolean>(false)

  const [changesDetected, setChangesDetected] = useState<boolean>(false)
  const [showSaveAs, setShowSaveAs] = useState<boolean>(false)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      changesDetected && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    setTemplateSelection(false)
    if (templateId) {
      send({ type: 'SELECT_TEMPLATE', id: templateId })
    } else {
      send({ type: 'CHANGE_TEMPLATE' })
      if (logicalPath && logicalPath !== 'recently-opened') {
        send({ type: 'SELECT_NODE', path: logicalPath })
      }
    }
  }, [send, templateId, logicalPath])

  useEffect(() => {
    if (state.matches('NODE_NOT_FOUND_ERROR')) {
      navigate('/not-found')
    }

    if (state.matches({ TEMPLATE_EXPLORER: 'GROUP_LOAD_ERROR' })) {
      send({ type: 'CLEAN_LOAD_ERROR' })
    }

    if (state.value === 'LOADING_TEMPLATE') {
      currentTemplateId.current = undefined
    }

    if (state.value === 'NODE_SELECTION') {
      if (state.context.selectedTemplateId) {
        send({ type: 'SELECT_TEMPLATE', id: state.context.selectedTemplateId })
      } else {
        send({ type: 'CHANGE_TEMPLATE' })
        if (state.context.selectedPath) {
          send({ type: 'SELECT_GROUP', path: state.context.selectedPath })
        }
      }
    }

    setTemplateSelection(
      state.matches('TEMPLATE_EXPLORER') || location.pathname === '/',
    )
  }, [state, send, location.pathname, navigate])

  const loadingTemplate = state.matches('LOADING_TEMPLATE')
  const isSaving = state.matches({ STUDIO: 'UPDATE_TEMPLATE' })
  const templateLoaded =
    state.matches('STUDIO') ||
    (state.matches('TEMPLATE_EXPLORER') &&
      state.context.selectedTemplateId !== null)

  const isNavigating = state.matches({ STUDIO: 'NAVIGATING' })

  const template = getTemplate()
  if (templateLoaded) {
    if (currentTemplateId.current !== state.context.selectedTemplateId) {
      currentTemplateId.current = state.context.selectedTemplateId || undefined

      setRecentlyOpened(template.id)
    }
  }

  const onTemplateExplorerClick = () => {
    dismissAllNotifications()

    const { groupMapping } = getTemplate()
    const pathname =
      groupMapping && groupMapping.path
        ? `/${encodePath(groupMapping.path).split('>').slice(0, -1).join('/')}`
        : '/'

    navigate({
      pathname,
      search: window.location.search,
    })

    if (changesDetected === false) {
      send({ type: 'CHANGE_TEMPLATE' })
    }
  }

  const onSaveAsClick = () => {
    setShowSaveAs(true)
  }

  const topHeight =
    HEADER_HEIGHT_PX +
    TOOLBAR_HEIGHT_PX +
    (debugToolBarEnabled ? DEBUG_TOOLBAR_HEIGHT_PX : 0)

  return (
    <>
      {debugEnabled && debugToolBarEnabled && <DebugToolbar />}

      <LeaveDialog
        open={blocker.state === 'blocked'}
        handleCancel={() => {
          if (blocker.state === 'blocked') {
            blocker.reset()
          }

          return true
        }}
        handleConfirm={() => {
          if (blocker.state === 'blocked') {
            send({ type: 'CHANGE_TEMPLATE' })
            setChangesDetected(false)
            blocker.proceed()
          }
          return true
        }}
      />

      {showSaveAs && (
        <SaveAsDialog
          onClose={() => setShowSaveAs(false)}
          template={{
            id: template.id,
            name: template.displayName,
            parentPath: template.groupMapping.parentPath,
          }}
          onDuplicated={(path: string) => {
            setShowSaveAs(false)

            navigate('/' + path)
          }}
        />
      )}

      <TemplateGlobalToolbar
        onTemplateExplorerClick={onTemplateExplorerClick}
        onSaveAsClick={onSaveAsClick}
      />

      <StyledMain
        id={'studio-main'}
        style={{
          position: 'fixed',
          top: `${topHeight}px`,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <>
          <Notifications />
          <TemplateExplorer
            show={templateSelection}
            onSearch={searchTemplateByName}
            logicalPath={logicalPath}
          />
        </>

        {loadingTemplate && !templateLoaded && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <LoadingIndicator label="Loading template" />
          </div>
        )}

        {templateLoaded && (
          <StyledGrid>
            <SceneProvider>
              <StyledSideMenu>
                {!isSaving && <ElementSelectionControls />}
              </StyledSideMenu>
              <StyledMainContent>
                <ActionsToolbar />
                <SceneRendererContainer onSceneChanges={setChangesDetected} />
              </StyledMainContent>
              <StyledSideMenu>
                <SceneElementEditControls />
                {isNavigating && <TemplateSettingsControls />}
              </StyledSideMenu>
            </SceneProvider>
          </StyledGrid>
        )}
      </StyledMain>
    </>
  )
}

type StudioStateProviderProps = {
  user: StudioUser
}

export const StudioStateProvider: FC<
  PropsWithChildren<StudioStateProviderProps>
> = ({ children, user }) => {
  const isMounted = useIsMounted()
  const templateExplorer = useTemplateExplorer()
  const fontProvider = useFontProvider()
  const intentProvider = useIntentProvider()
  const templateProvider = useTemplateProvider()

  const { showFileTypeIncorrectError } = useErrorMessages()
  const dismissAllErrorNotifications = useDismissAllErrorNotifications()

  const onFetchRootGroup = (group: StudioGroup) => {
    isMounted() &&
      templateExplorer.dispatch({
        type: 'SET_ROOT_GROUP',
        payload: { group },
      })
  }

  const onFetchGroupByPath = (group: StudioGroup) => {
    isMounted() &&
      templateExplorer.dispatch({
        type: 'SET_CURRENT_GROUP',
        payload: { group },
      })
  }

  const onFetchFonts = (fonts: StudioFont[]) => {
    isMounted() &&
      fontProvider.dispatch({
        type: 'SET_FONTS',
        fonts,
      })
  }

  const onFetchIntents = (intents: StudioIntent[]) => {
    isMounted() &&
      intentProvider.dispatch({
        type: 'SET_INTENTS',
        intents,
      })
  }
  const onFetchTemplate = async (template: StudioTemplate) => {
    dismissAllErrorNotifications()

    const { validatedElements, oversizedImages } = await validateElements(
      template.elements,
    )

    if (oversizedImages.length > 0) {
      const oversizedFileNames = oversizedImages
        .map(file => `${file.name} (${file.sizeMB}MB)`)
        .join(', ')

      showFileTypeIncorrectError(
        `${FILE_TOO_BIG_FAILED_ACCEPT_MESSAGE}: ${oversizedFileNames}`,
      )
    }

    const validatedTemplate = { ...template, elements: validatedElements }

    isMounted() &&
      templateProvider.dispatch({
        type: 'SET_TEMPLATE',
        template: validatedTemplate,
      })
  }

  return (
    <StateMachineProvider
      user={user}
      onFetchTemplate={onFetchTemplate}
      onFetchRootGroup={onFetchRootGroup}
      onFetchGroupByPath={onFetchGroupByPath}
      onFetchFonts={onFetchFonts}
      onFetchIntents={onFetchIntents}
    >
      {children}
    </StateMachineProvider>
  )
}

export const StudioEditor: FC<{
  templateId: string
  user: StudioUser
  logicalPath: string
  debugToolBarEnabled: boolean
}> = ({ templateId, user, logicalPath, debugToolBarEnabled }) => {
  const activeUser = useMemo(() => user, [user])

  return (
    <>
      <GlobalStyle />
      <NotificationsProvider>
        <FontProvider>
          <IntentProvider>
            <TemplateProvider>
              <TemplateExplorerProvider>
                <UploadMachineProvider user={activeUser}>
                  <FontUploadMachineProvider user={activeUser}>
                    <StudioStateProvider user={activeUser}>
                      <StudioMain
                        templateId={templateId}
                        logicalPath={logicalPath}
                        debugToolBarEnabled={debugToolBarEnabled}
                      />
                    </StudioStateProvider>
                  </FontUploadMachineProvider>
                </UploadMachineProvider>
              </TemplateExplorerProvider>
            </TemplateProvider>
          </IntentProvider>
        </FontProvider>
      </NotificationsProvider>
    </>
  )
}
